import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import ProjectMetaTags from "../ProjectMetaTags";

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

import './flow.scss'
import 'react-pdf/dist/Page/AnnotationLayer.css';

import diagram from './flow-diagram.pdf'

class Flow extends React.Component {
  constructor(props){
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  }

  render() {
    return (
      <main id='flow'>
        <ProjectMetaTags title='How To Start' />
        <div className='pdf'>
          <Document file={diagram}>
            <Page pageNumber={1} renderTextLayer={false} />
          </Document>
        </div>
        <div className='download-pdf'>
          <div className='black-line'/>
          <span className='main'>DOWNLOAD THE<br />DIAGRAM</span>
          <span className='annotation'>Our diagram is meant to provide you with a clear idea of the flow following your application. Save a copy of this applicant journey diagram to your files. You never know, it may come in handy.</span>
          <a href={diagram} download className='btn'>DOWNLOAD PDF</a>
        </div>
      </main>
    );
  }
}

export default Flow;
