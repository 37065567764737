import React from 'react';
import * as PropTypes from 'prop-types';

import _ from 'lodash';

import './projects_section.scss';
import 'font-awesome/css/font-awesome.min.css';

import ProjectItem from './ProjectItem';

class ProjectsSection extends React.Component {
  render() {
    const projects = _.map(this.props.projects, (project) => {
      return(
        <ProjectItem
          key={project.id}
          project={project}
        />
      );
    });

    return(
      <div className='projects-section'>
        {projects}
      </div>
    );
  }
}

ProjectsSection.defaultProps = {
  projects: [],
};

ProjectsSection.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ProjectsSection;
