import React from 'react';
import axios from 'axios';
import * as _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import ProjectMetaTags from '../ProjectMetaTags';
import ProjectsSection from './ProjectsSection';
import StatisticBox from '../Shared/StatisticBox';

import './projects.scss';
import 'font-awesome/css/font-awesome.min.css';

class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props,
      technologies: [],
      languages: [],
    };

    this.fetchProjects = this.fetchProjects.bind(this);
    this.onTechnologyChange = this.onTechnologyChange.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.mergedSelectedItems = this.mergedSelectedItems.bind(this);
    this.isClearActionsVisible = this.isClearActionsVisible.bind(this);
  }

  componentDidMount() {
    axios.get('/api/v1/projects.json').then((res) => {
      const {
        projects, site_description, projects_count, contributors_count
      } = res.data.data;
      const technologies = _.keyBy(res.data.data.technologies, 'id');
      const languages = _.keyBy(res.data.data.languages, 'id');

      this.setState({
        projects,
        siteDescription: site_description,
        projectsCount: projects_count,
        contributorsCount: contributors_count,
        technologies,
        languages,
      });
    }).catch(error => console.log(error));
  }

  onTechnologyChange(event) {
    const { technologies } = this.state;
    const currentId = event.target.name;
    const isFiltered = _.get(technologies[currentId], 'filtered', false);

    technologies[currentId].filtered = !isFiltered;

    this.setState({ technologies });

    this.fetchProjects();
  }

  onLanguageChange(event) {
    const { languages } = this.state;
    const currentId = event.target.name;
    const isFiltered = _.get(languages[currentId], 'filtered', false);

    languages[currentId].filtered = !isFiltered;

    this.setState({ languages });

    this.fetchProjects();
  }

  fetchProjects() {
    const technologyIds = _.map(_.filter(this.state.technologies, item => item.filtered), 'id');
    const languageIds = _.map(_.filter(this.state.languages, item => item.filtered), 'id');

    axios.get('/api/v1/projects.json', { params: { technologies: technologyIds, languages: languageIds } })
      .then((res) => {
        const { projects } = res.data.data;

        this.setState({ projects });
      }).catch(error => console.log(error));
  }

  clearFilters(event) {
    event.preventDefault();

    const technologies = _.each(this.state.technologies, (item) => { _.set(item, 'filtered', false); });
    const languages = _.each(this.state.languages, (item) => { _.set(item, 'filtered', false); });

    this.setState({ technologies, languages });

    this.fetchProjects();
  }

  removeItem(event, selectedItem) {
    event.preventDefault();

    if (selectedItem.type === 'technology') {
      const { technologies } = this.state;
      const currentId = selectedItem.id;
      const isFiltered = _.get(technologies[currentId], 'filtered', false);

      technologies[currentId].filtered = !isFiltered;

      this.setState({ technologies });
    } else {
      const { languages } = this.state;
      const currentId = selectedItem.id;
      const isFiltered = _.get(languages[currentId], 'filtered', false);

      languages[currentId].filtered = !isFiltered;

      this.setState({ languages });
    }

    this.fetchProjects();
  }

  mergedSelectedItems() {
    const selectedTechnologies = _.filter(this.state.technologies, item => item.filtered);
    _.each(selectedTechnologies, (item) => { _.set(item, 'type', 'technology'); });

    const selectedLanguages = _.filter(this.state.languages, item => item.filtered);
    _.each(selectedLanguages, (item) => { _.set(item, 'type', 'language'); });

    return [...selectedTechnologies, ...selectedLanguages];
  }

  isClearActionsVisible() {
    const { technologies, languages } = this.state;
    const checkTechnologies = _.find(technologies, item => item.filtered);
    const checkLanguages = _.find(languages, item => item.filtered);

    return checkTechnologies || checkLanguages;
  }

  render() {
    const {
      projects, featuredProjects, siteDescription, projectsCount, contributorsCount, starsCount, technologies, languages,
    } = this.state;

    const statisticData = {
      'Projects': projectsCount,
      'Members': contributorsCount,
      'Stars': starsCount,
    };

    const technologyCheckboxes = _.map(_.sortBy(technologies, [function (item) { return item.name; }]), technology => (
      <div className="checkbox" key={`technology-${technology.id}`}>
        <input
          type="checkbox"
          id={`technology-${technology.id}`}
          name={technology.id}
          checked={_.get(technology, 'filtered', false)}
          onChange={this.onTechnologyChange}
        />
        <label htmlFor={`technology-${technology.id}`}>{technology.name}</label>
      </div>
    ));

    const languageCheckboxes = _.map(_.sortBy(languages, [function (item) { return item.name; }]), language => (
      <div className="checkbox" key={`language-${language.id}`}>
        <input
          type="checkbox"
          id={`language-${language.id}`}
          name={language.id}
          checked={_.get(language, 'filtered', false)}
          onChange={this.onLanguageChange}
        />
        <label htmlFor={`language-${language.id}`}>{language.name}</label>
      </div>
    ));

    const selectedItems = _.map(this.mergedSelectedItems(), (selectedItem, index) => (
      <div key={index} className="selected-item">
        {selectedItem.name}
        <button onClick={e => this.removeItem(e, selectedItem)}>
          <i className="fa fa-times" />
        </button>
      </div>
    ));

    const clearingActionsStyle = this.isClearActionsVisible() ? '' : 'hidden';
    const badgesStyle = this.isClearActionsVisible() ? 'badges' : 'badges hidden';

    let projectSection = '';

    if (projects.length !== 0) {
      projectSection = <ProjectsSection projects={projects} />;
    } else {
      projectSection = <p className="empty-results">No results, try to change your filters</p>;
    }

    return (
      <main className="projects">
        <ProjectMetaTags title="Projects" description={siteDescription} />

        <div className="projects-wrapper">
          <div className="header-wrapper">
            <h1>Open Source Projects</h1>
            <Link to="/submit-a-project" className="btn join-our-team-link">Join our team</Link>
          </div>
          <StatisticBox data={statisticData} />
          <p className="annotation">{ReactHtmlParser(siteDescription)}</p>
          {featuredProjects.length !== 0
              && (
              <div className="featured-section">
                <h2>Featured Projects</h2>
                <ProjectsSection projects={featuredProjects} />
              </div>
              )
            }

          <div className="filters">
            <form noValidate>
              <div className="main-elements-wrapper">
                <div className="form-group">
                  <Button id="languageCheckboxes" type="button">
                    Language
                    <i className="fa fa-sort-down" />
                  </Button>
                  <UncontrolledPopover trigger="legacy" placement="bottom" target="languageCheckboxes">
                    <PopoverBody>{languageCheckboxes}</PopoverBody>
                  </UncontrolledPopover>
                </div>
                <div className="form-group">
                  <Button id="technologyCheckboxes" type="button">
                    Technology
                    <i className="fa fa-sort-down" />
                  </Button>
                  <UncontrolledPopover trigger="legacy" placement="bottom" target="technologyCheckboxes">
                    <PopoverBody>{technologyCheckboxes}</PopoverBody>
                  </UncontrolledPopover>
                </div>
                <button onClick={this.clearFilters} className={clearingActionsStyle}>clear all filters</button>
              </div>
              <div className={badgesStyle}>{selectedItems}</div>
            </form>
          </div>

          {projectSection}
        </div>
      </main>
    );
  }
}

export default Projects;
