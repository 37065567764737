import React from 'react';
import axios from 'axios';
import * as PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import ProjectMetaTags from '../ProjectMetaTags';

import './our_mission.scss';
import img from './Rocket.png';
import { Link } from 'react-router-dom';

class OurMission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mission: '',
    };
  }

  componentDidMount() {
    axios.get(`/api/v1/missions.json`).then(res => {
      const mission = res.data.data.mission;
      this.setState({ mission });
    }).catch(error => console.log(error));
  }

  render(){
    const { mission } = this.state;
    return(
      <main className='our-mission'>
        <ProjectMetaTags title='Our Mission' description={mission} />
        <div className='content-wrapper'>
          <div className='title-wrapper'>
            <h1 className='our-mission-title'>
              Our Mission
              <img src={img} alt='Image' className='rocket'/>
            </h1>
          </div>
          <div className='our-mission-text'>
            { ReactHtmlParser(mission) }
          </div>
          <Link to='/submit-a-project' className='btn'>Get in touch</Link>
        </div>
      </main>
    );
  }
};

export default OurMission;
