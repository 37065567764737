import React from 'react';
import * as PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

const Links = (props) => {
  const { handleLinkClick } = props;
  return (
    <div className="links">
      <div><Link to="/" onClick={handleLinkClick}>Projects</Link></div>
      <div><Link to="/community" onClick={handleLinkClick}>Community</Link></div>
      <div><Link to="/mission" onClick={handleLinkClick}>Our mission</Link></div>
      <div><Link to="/committee" onClick={handleLinkClick}>Committee</Link></div>
      <div><Link to="/how-to-start" onClick={handleLinkClick}>How to Start</Link></div>
      <div><Link to="/submit-a-project" className="submit-project-link" onClick={handleLinkClick}>Submit a project</Link></div>
    </div>
  );
};

Links.defaultProps = {
  handleLinkClick: () => {},
};

Links.propTypes = {
  handleLinkClick: PropTypes.func,
};

export default Links;
