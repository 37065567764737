import React from "react";

import facebookIcon from '../../../images/social_networks_icons/facebook.svg';
import twitterIcon from '../../../images/social_networks_icons/twitter.svg';
import githubIcon from '../../../images/social_networks_icons/github.svg';
import linkedinIcon from '../../../images/social_networks_icons/linkedin.svg';

function SocialNetworkLinks({ developer }) {
  const { facebook, twitter, github, linkedin } = developer;
  return (
    <div className='social-networks-links'>
      { facebook &&
        <a href={facebook} target='_blank'>
          <img src={facebookIcon} alt='Facebook icon'/>
        </a>
      }
      { twitter &&
        <a href={twitter} target='_blank'>
          <img src={twitterIcon} alt='Twitter icon'/>
        </a>
      }
      { github &&
        <a href={github} target='_blank'>
          <img src={githubIcon} alt='GitHub icon'/>
        </a>
      }
      { linkedin &&
        <a href={linkedin} target='_blank'>
          <img src={linkedinIcon} alt='LinkedIn icon'/>
        </a>
      }
    </div>
  );
}

export default SocialNetworkLinks;
