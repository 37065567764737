import React from 'react';

import './page_not_found.scss';

function PageNotFound() {
  return(
    <main className='error'>
      <div className='content'>
        <h1>ERROR 404</h1>
        <p className="title-description">
          Oops, the page you're looking for isn't available.
        </p>
      </div>
    </main>
  );
}

export default PageNotFound;
