import React from 'react';

import './committee_item.scss';

const CommitteeItem = ({avatar, fullName, position, linkedInUrl}) => (
	<div className='committee-item'>
		<div className='committee-item-wrapper'>
			<img src={avatar} alt={fullName} />
			<a href={linkedInUrl} className='committee-fullname' target='_blank' rel="noopener noreferrer">{fullName}</a>
			<span className='position'>{position}</span>
		</div>
	</div>
);

export default CommitteeItem;
