import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';
import * as PropTypes from 'prop-types';

import Header from './Header';
import Projects from './Projects';
import Project from './Project';
import Developer from './Developer';
import Community from './Community';
import Footer from './Footer';
// import WithTracker from './analitics';
import OurMission from './OurMission';
import ContactUs from './ContactUs';
import Committee from './Committee';
import Flow from './Flow';
import PageNotFound from './PageNotFound';

import '../stylesheets/app_component.scss';

export default function App (props) {
  useEffect(() => {
    loadReCaptcha();
  }, []);

    const { siteDescription } = props;

    const siteDescriptionText = siteDescription.site_description_text;

    return (
      <Router>
        <div className="application" id="root">
          {/* <WithTracker /> */}
          <Header />
          <Routes>
            <Route
              path="/"
              element={<Projects {...props} siteDescription={siteDescriptionText} />}
            />
            <Route path="/projects/:slug" element={<Project />} />
            <Route
              path="/community"
              element={<Community {...props} />}
            />
            <Route path="/developers/:slug" element={<Developer />}/>
            <Route path="/mission" element={<OurMission />} />
            <Route path="/submit-a-project" element={<ContactUs />} />
            <Route path="/committee" element={<Committee />} />
            <Route path="/how-to-start" element={<Flow />} />
            <Route element={<PageNotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    );
}

App.defaultProps = {
  projects: [],
  featuredProjects: [],
  siteDescription: {},
  projectsCount: 0,
  starsCount: 0,
  developersCount: 0,
  contributorsCount: 0,
  reviewersCount: 0,
  supportersCount: 0,
};

App.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  featuredProjects: PropTypes.arrayOf(PropTypes.shape({})),
  siteDescription: PropTypes.shape({}),
  projectsCount: PropTypes.number,
  starsCount: PropTypes.number,
  developersCount: PropTypes.number,
  contributorsCount: PropTypes.number,
  reviewersCount: PropTypes.number,
  supportersCount: PropTypes.number,
};
