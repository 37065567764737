import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import DeveloperItem from './DeveloperItem';

import './developers_section.scss';

function DevelopersSection({ title, developers }) {
  const developersItems = _.map(developers, developer => (
    <DeveloperItem
      key={developer.id}
      avatar={developer.avatar}
      color={developer.color}
      overlayText={developer.position}
      slug={developer.slug}
    />
  ));

  return (
    <div className="section-wrapper">
      <h2 className="section-title">{title}</h2>
      <div className="developers-wrapper">
        {developersItems}
      </div>
    </div>
  );
}

DevelopersSection.defaultProps = {
  title: 'Community',
  developers: [],
};

DevelopersSection.propTypes = {
  title: PropTypes.string,
  developers: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DevelopersSection;
