import React from 'react';

import './hot_links.scss';

const HotLinks = () => (
  <div className='footer-hot-links'>
    <h2>Hot links</h2>
    <div className="footer-hot-links-block">
      <ul>
        <li><a href="https://softserveinc.com/en-us/" target='_blank'>Home</a></li>
        <li><a href="https://softserveinc.com/en-us/your-journey/reveal/" target='_blank'>Your Journey</a></li>
        <li><a href="https://softserveinc.com/en-us/industries/" target='_blank'>Industries</a></li>
        <li><a href="https://softserveinc.com/en-us/solutions/" target='_blank'>Solutions</a></li>
        <li><a href="https://softserveinc.com/en-us/resources/" target='_blank'>Resources</a></li>
        <li><a href="https://softserveinc.com/en-us/press/news/" target='_blank'>Press &amp; Events</a></li>
      </ul>
      <ul>
        <li><a href="https://softserveinc.com/en-us/career/" target='_blank'>Careers</a></li>
        <li><a href="https://softserveinc.com/en-us/about-us/" target='_blank'>About Us</a></li>
        <li><a href="https://softserveinc.com/en-us/softserve-university/" target='_blank'>University</a></li>
        <li><a href="https://softserveinc.com/en-us/blog/" target='_blank'>Blog</a></li>
        <li><a href="https://softserveinc.com/en-us/contact/" target='_blank'>Contact</a></li>
      </ul>
      <ul>
        <li><a href="https://career.softserveinc.com/vacancies" target='_blank'>Job Openings</a></li>
        <li><a href="https://career.softserveinc.com/technology" target='_blank'>IT Academy</a></li>
      </ul>
    </div>
  </div>
);

export default HotLinks;
