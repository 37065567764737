import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './project_item.scss';
import 'font-awesome/css/font-awesome.min.css';

const ProjectItem = (props) => {
  const { project } = props;
  const { slug, name, icon, short_description, stars_count, forks_count, open_issues_count } = project;

  let nameStyle = {};
  if (name.length < 14) {
    nameStyle = {
      paddingTop: 10,
    };
  }

  return (
    <div className='project'>
      <Link to={{ pathname: `/projects/${slug}`, state: { project: project} }} className='project-wrapper' >
        <div className='project-content-wrapper'>
          <div className='project-header'>
            <div className='project-name' style={nameStyle}>
              <h3>{name}</h3>
            </div>
            <div className='project-technology-icon'>
              <img src={icon} alt='Technology icon'/>
            </div>
          </div>
          <div className='project-description'>
            {short_description}
          </div>
        </div>
        <div className='project-git-info'>
          <div className='star'>
            <i className='fa fa-star'/>
            {stars_count}
          </div>
          <div className='fork'>
            <svg fill="#00AE83" width={14} height={14} viewBox="-64 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#00AE83"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z"></path></g></svg>
            {forks_count}
          </div>
          <div className='open_issues'>
            <i className='fa fa-exclamation-circle'/>
            {open_issues_count}
          </div>
        </div>
        <div className="view-project">
          view project
        </div>
      </Link>
      <div className='background'/>
    </div>
  );
};

ProjectItem.dafaultProps = {
  project: {},
};

ProjectItem.propTypes = {
  project: PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    short_description: PropTypes.string,
    stars_count: PropTypes.number,
    forks_count: PropTypes.number,
    open_issues_count: PropTypes.number,
    background_image: PropTypes.string,
    repository_url: PropTypes.string,
  })
};

export default ProjectItem;
