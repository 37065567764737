import React from 'react';
import classNames from 'classnames/bind';

import Logo from './Logo';
import Links from './Links';

import './header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleLinkClick  = this.handleLinkClick.bind(this);
  }

  handleToggleMenu() {
    this.setState((state) => {
      return {
        isOpen: !state.isOpen
      }
    }, () => {
      if(this.state.isOpen) {
        document.body.classList.add('hide-overflow');
      } else {
        document.body.classList.remove('hide-overflow');
      }
    });
  }

  handleLinkClick() {
    document.body.classList.remove('hide-overflow');
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen} = this.state;
    const links = isOpen ?
      <div className='burger-menu'>
        <Links handleLinkClick={this.handleLinkClick} />
      </div> : <Links />;

    const burgerClasses = classNames('burger', { 'active': isOpen });

    return(
      <header>
        <Logo />
        <div className='nav'>
          <div onClick={this.handleToggleMenu} className={burgerClasses}>
            <div className='burger-wrapper'>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        {links}
      </header>
    )
  }
}

export default Header;
