import React from 'react';
import * as PropTypes from 'prop-types';

import './project-header.scss';
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom';

function ProjectHeader(props) {
  const { project } = props;
  const { name, icon, short_description, stars_count, forks_count, open_issues_count, repository_url } = project;
  return (
    <div className="overlay">
      <div className = "wrapper-header">
        <div className="flex-container space-between">
          <div className="info">
            <div className="flex-container">
              <img src={icon} alt="Technology Logo"/>
              <h1 className="title">{name}</h1>
            </div>
            <p className="description">{short_description}</p>
            <div className="project-git-info flex-container ">
              <Link to={`${repository_url}`} target="_blank" className="statistic flex-container without-decoration">
                <div className="statistic-item">
                  <i className="fa fa-star" />
                  <span>stars</span>
                </div>
                <span className="statistic-count">{stars_count}</span>
              </Link>

              <Link to={`${repository_url}/network/members`} target="_blank" className="statistic flex-container without-decoration">
                <div className="statistic-item">
                  <svg fill="#ffffff" width={16} height={16} viewBox="-64 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z"></path></g></svg>
                  <span>forks</span>
                </div>
                <span className="statistic-count">{forks_count}</span>
              </Link>

              <Link to={`${repository_url}/issues`} target="_blank" className="statistic flex-container without-decoration">
                <div className="statistic-item">
                  <i className="fa fa-exclamation-circle" />
                  <span>issues</span>
                </div>
                <span className="statistic-count">{open_issues_count}</span>
              </Link>
            </div>
          </div>
          <a className="main-link" href={repository_url} target='_blank'>Get the code</a>
        </div>
      </div>
    </div>
  )
};

ProjectHeader.defaulProps = {
  project: {}
}

ProjectHeader.propTypes = {
  project: PropTypes.shape({})
}

export default ProjectHeader;
