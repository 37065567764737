import React from 'react';

import privacy_policy from './privacy_policy.pdf'
import './menu.scss'

const Menu = () => (
  <div className='menu'>
    <div className='nav'>
      <a href={privacy_policy} target='_blank'>Privacy Policy</a>
      <span>-</span>
      <a href='https://softserveinc.com/en-us/terms-and-conditions/' target='_blank'>Terms and Conditions</a>
      <span>-</span>
      <a href='https://softserveinc.com/en-us/sitemap/' target='_blank'>Sitemap</a>
      <span>-</span>
      <a href='https://softserveinc.com/en-us/search/' target='_blank'>Search</a>
    </div>
    <div className='social'>
      <a href='https://www.facebook.com/SoftServeInc/' target='_blank'>Facebook</a>
      <span>-</span>
      <a href='https://twitter.com/SoftServeInc' target='_blank'>Twitter</a>
      <span>-</span>
      <a href='https://www.linkedin.com/company/softserve' target='_blank'>LinkedIn</a>
      <span>-</span>
      <a href='https://www.youtube.com/user/SoftServeInc' target='_blank'>YouTube</a>
    </div>
  </div>
);

export default Menu;
