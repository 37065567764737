import React from 'react';
import { Link } from "react-router-dom";

import logo from '../../../images/softserve-white.svg';

const Logo = () => (
  <div className='logo'>
    <Link to='/'>
      <img src={logo} alt='SoftServe logo' />
      <span>OpenSource</span>
    </Link>
  </div>
);

export default Logo;
