import React from 'react';
import axios from 'axios';
import { ReCaptcha } from 'react-recaptcha-google'

import ContactUsGreeting from './ContactUsGreeting';
import ProjectMetaTags from '../ProjectMetaTags';

import { CAPTCHA_KEY } from "../constants";

import './contact_us.scss';
import privacy_policy from '../Footer/Menu/privacy_policy.pdf';


class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {recaptchaToken: '', disableSubmit: true};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.setRecaptchaToken = this.setRecaptchaToken.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }

  setRecaptchaToken(recaptchaToken) {
    this.setState({recaptchaToken: recaptchaToken});
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.captcha) {
      this.captcha.reset();
      this.captcha.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset();
      this.captcha.execute();
    }
  }

  handleChanges(event) {
    this.setState({ disableSubmit: (!event.target.checked) });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      this.setState({ displayErrors: true });
      return;
    }
    this.setState({ displayErrors: false });

    const contact = {
      name: event.target.elements.name.value,
      email: event.target.elements.email.value,
      github_url: event.target.elements.github_url.value,
      message: event.target.elements.message.value,
      agreement: event.target.elements.agreement.value
    };
    const recaptchaToken = this.state.recaptchaToken;

    let self = this;
    axios.post(`api/v1/contacts`, { contact, recaptchaToken, headers: { 'Content-Type': 'application/json' }})
      .then(function (response) {
        self.setState({renderNewComponent: true});
      });
  }

  render(){
    const { displayErrors, disableSubmit, captchaToken } = this.state;
    const cssError = displayErrors ? 'form-element displayErrors' : 'form-element';

    if (this.state.renderNewComponent) return <ContactUsGreeting />;

    return(
      <main>
        <ProjectMetaTags title='Submit A Project'/>
        <div className='form-wrapper'>
          <h1 className='contact-us-title'>Submit a Project</h1>
          <p className='contact-us-subtitle'>We'd love to hear from you! Please, fill in the form below.</p>
          <form onSubmit={this.handleSubmit} noValidate>
            <input type='text'
                   name='name'
                   className={cssError}
                   placeholder='* Name'
                   required />
            <input type='email'
                   name='email'
                   className={cssError}
                   placeholder='* Email'
                   required />
            <input type='text'
                   name='github_url'
                   className={cssError}
                   placeholder='GitHub (optional)' />
            <textarea name='message'
                      className={cssError}
                      placeholder='* Message (Please, describe your ideas)'
                      required />
            <div className='checkbox-form-element'>
              <label className='checkbox-button'>
                <input type='checkbox' className='checkbox-button__input' name='agreement' value={true} onChange={this.handleChanges} />
                <span className='checkbox-button__control' />
                <span className='checkbox-button__label'>
                  * I have read and accepted the <a href='https://softserveinc.com/en-us/terms-and-conditions/' target='_blank'>Terms & Conditions</a> and <a href={privacy_policy} target='_blank'>Privacy Policy</a>.
                </span>
              </label>
            </div>
            <div className={displayErrors ? 'error-message' : 'error-message hide'}>PLEASE FILL OUT ALL THE FIELDS</div>
            <input type="submit" value="Send message" disabled={disableSubmit} />
            <ReCaptcha
              ref={(el) => {this.captcha = el;}}
              size="invisible"
              render="explicit"
              sitekey={CAPTCHA_KEY}
              onloadCallback={this.onLoadRecaptcha}
              verifyCallback={this.setRecaptchaToken}
            />
          </form>
        </div>
      </main>
    );
  }
}

export default ContactUs;
