import React from 'react';

import './contacts.scss';

const Contacts = () => (
  <div className='footer-contacts'>
    <h2>Contacts</h2>
    <div className='footer-contacts-block'>
      <div className='contact-info'>
        <h3>USA HQ</h3>
        <p>201 W 5th Street Suite 1550 <br /> Austin, TX 78701</p>
        <p><a href='tel:1-512-516-8880' target='_blank'>1-512-516-8880</a></p>
        <p>Toll free: <br /> <a href='tel:866-687-3588' target='_blank'>866-687-3588</a></p>
      </div>
      <div className='contact-info'>
        <h3>European HQ</h3>
        <p>2D Sadova Street<br />Lviv, Ukraine 79021</p>
        <p><a href="tel:380-32-240-9090" target='_blank'>380-32-240-9090</a></p>
      </div>
    </div>
  </div>
);

export default Contacts;

