import React from 'react';

import { Link } from 'react-router-dom';
import './contact_us_greeting.scss'

const ContactUsGreeting = () => (
	<main className='greeting-wrapper'>
		<h1 className='greeting'>Thank you for contacting us</h1>
		<p className='sub-greeting'>Your email has been successfully submitted and we will get in touch with you shortly</p>
		<Link to='/'>Back to main page</Link>
	</main>
);

export default ContactUsGreeting;
