import React from 'react';
import * as PropTypes from 'prop-types';
import { DEFAULT_DESCRITPION, APP_TITLE } from '../constants';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

export const ProjectMetaTags = ({title, description}) => {
  const metaTitle = _.join(_.compact([APP_TITLE, title]), ' | ');
  return(
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

ProjectMetaTags.defaultProps = {
  title: '',
  description: DEFAULT_DESCRITPION
}

ProjectMetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

export default ProjectMetaTags;
