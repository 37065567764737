import React from 'react';
import axios from 'axios';
import _ from 'lodash';

import ProjectsSection from '../Projects/ProjectsSection';
import SocialNetworkLinks from './SocialNetworkLinks';
import ProjectMetaTags from '../ProjectMetaTags';

import './developer.scss';
import WithRouter from '../Shared/WithRouter/WithRouter';

class Developer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    const slug = _.get(this.props.params, 'slug', '');

    axios.get(`/api/v1/developers/${slug}.json`).then(({ data }) => {
      const developer = data.data;

      this.setState({
        developer,
        loading: !this.state.loading
      });
    }).catch(error => console.log(error));

    window.scrollTo(0, 0);
  }

  render() {
    const { loading, developer } = this.state;
    return (
      <main className='developer'>
        { !loading &&
          <React.Fragment>
            <ProjectMetaTags title={developer.full_name} />
            <div className='developer-wrapper'>
              <div className='developer-section'>
                <div className='developer-avatar-wrapper'>
                  <img src={developer.avatar} />
                </div>

                <div className='developer-info-wrapper'>
                  <h2 className='developer-fullname'>{developer.full_name}</h2>
                  <h3 className='developer-position'>{developer.position}</h3>
                  <SocialNetworkLinks developer={developer}/>
                </div>
              </div>

              <h2 className='contributor-projects'>Projects & Contributions</h2>
              <div className='developer-projects'>
                <ProjectsSection projects={developer.projects}/>
              </div>
            </div>
          </React.Fragment>
        }
      </main>
    );
  }
}

export default WithRouter(Developer);
