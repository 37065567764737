import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import './statistic_box.scss';

function StatisticBox({ data }) {
  const itemWidth = `${100 / _.size(data)}%`;

  const statisticItem = (
    _.map(data, (value, key) => (
      <div key={key} className="statistic-item" style={{ width: itemWidth }}>
        <span className="header">{key}</span>
        <span className="value">{value}</span>
      </div>
    ))
  );

  return (
    <div className="statistic-wrapper">
      {statisticItem}
    </div>
  );
}

StatisticBox.defaultProps = {
  data: {},
};

StatisticBox.propTypes = {
  data: PropTypes.shape({}),
};

export default StatisticBox;
