import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import * as PropTypes from 'prop-types';

import ProjectHeader from './ProjectHeader';
import ProjectInfo from './ProjectInfo';
import ProjectMetaTags from '../ProjectMetaTags';

import WithRouter from '../Shared/WithRouter/WithRouter';

import './project.scss';

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: _.get(this.props.location.state, 'project', {})
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(slug) {
    axios.get(`/api/v1/projects/${slug}.json`).then(res => {
      const project = res.data.data;
      this.setState({ project });
    }).catch(error => console.log(error));
  }

  componentDidMount() {
    const projectId = _.get(this.props.params, 'slug', '');
    window.scrollTo(0, 0);

    this.fetchData(projectId);
  }

  componentDidUpdate() {
    const nextProjectId = _.get(this.props.params, 'slug', '');

    if (nextProjectId != this.state.project.slug) {
      this.fetchData(nextProjectId);
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {project} = this.state;
    return (
      <div>
        <ProjectMetaTags title={project.name} description={project.short_description} />
        <ProjectHeader project={project} />
        <ProjectInfo project={project} />
      </div>
    );
  };
};

Project.defaultProps = {
  location: { state: { project: {} } }
};

Project.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      project: PropTypes.shape({})
    })
  })
};

export default WithRouter(Project);
