import React from "react";
import { Link } from "react-router-dom";

import SocialNetworkLinks from "../../../Developer/SocialNetworkLinks";

import './contributor.scss';

function Contributor({ developer }) {
  const { slug, avatar, full_name, position } = developer;

  return (
    <div className='contributor-wrapper' >
      <Link to={`/developers/${slug}`} className='profile-link'>
        <img src={avatar} alt='Developer avatar' />
      </Link>
      <div className='info-wrapper' >
        <Link to={`/developers/${slug}`} className='profile-link'>
          <p className='fullname'>{full_name}</p>
        </Link>
        <p className='position'>{position}</p>
        <SocialNetworkLinks developer={developer} />
      </div>
    </div>
  );
}

export default Contributor;
