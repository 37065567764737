import React from 'react';
import axios from 'axios';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';

import ProjectMetaTags from '../ProjectMetaTags';
import StatisticBox from '../Shared/StatisticBox';
import DevelopersSection from './DevelopersSection';

import './community.scss';

class Community extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contributors: [],
      reviewers: [],
      supporters: [],
      ...this.props,
    };
  }

  componentDidMount() {
    axios.get('/api/v1/developers.json').then((res) => {
      const {
        contributors, reviewers, supporters,
        developers_count, contributors_count, reviewers_count, supporters_count
      } = res.data.data;

      this.setState({
        contributors,
        reviewers,
        supporters,
        developersCount: developers_count,
        contributorsCount: contributors_count,
        reviewersCount: reviewers_count,
        supportersCount: supporters_count,
      });
    });
  }

  render() {
    const {
      contributors, reviewers, supporters, developersCount,
      contributorsCount, reviewersCount, supportersCount,
    } = this.state;

    const statisticData = {
      'Members': developersCount,
      Contributors: contributorsCount,
      Reviewers: reviewersCount,
      Supporters: supportersCount,
    };

    let contributorsSection = '';
    if (!(contributorsCount === 0)) {
      contributorsSection = <DevelopersSection title="Contributors" developers={contributors} />;
    }

    let reviewersSection = '';
    if (!(reviewersCount === 0)) {
      reviewersSection = <DevelopersSection title="Reviewers" developers={reviewers} />;
    }

    let supportersSection = '';
    if (!(supportersCount === 0)) {
      supportersSection = <DevelopersSection title="Supporters" developers={supporters} />;
    }

    return (
      <main className="community">
        <ProjectMetaTags title="Community" />
        <div className="community-wrapper">
          <div className="header-wrapper">
            <h1>Open Source Community</h1>
            <Link to="/submit-a-project" className="btn join-our-team-link">Join our team</Link>
          </div>
          <StatisticBox data={statisticData} />
          <div className="developers-wrapper">
            {contributorsSection}
            {reviewersSection}
            {supportersSection}
          </div>
        </div>
      </main>
    );
  }
}

Community.defaultProps = {
  contributors: [],
  reviewers: [],
  supporters: [],
  developersCount: 0,
  contributorsCount: 0,
  reviewersCount: 0,
  supportersCount: 0,
};

Community.propTypes = {
  contributors: PropTypes.arrayOf(PropTypes.shape({})),
  reviewers: PropTypes.arrayOf(PropTypes.shape({})),
  supporters: PropTypes.arrayOf(PropTypes.shape({})),
  developersCount: PropTypes.number,
  contributorsCount: PropTypes.number,
  reviewersCount: PropTypes.number,
  supportersCount: PropTypes.number,
};

export default Community;
