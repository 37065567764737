import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './developer_item.scss';

import defaultAvatar from '../../../../images/default_avatar.png';

const DeveloperItem = ({ avatar, color, overlayText, slug }) => (
  <Link to={`/developers/${slug}`} className="item">
    <div className="content-wrapper">
      <img src={avatar} alt="Developer avatar" />
      <div className="overlay">
        <div className="text" style={{ color }}>{overlayText}</div>
      </div>
    </div>
  </Link>
);

DeveloperItem.defaultProps = {
  avatar: defaultAvatar,
  color: '#131313',
  overlayText: '',
};

DeveloperItem.propTypes = {
  avatar: PropTypes.string,
  color: PropTypes.string,
  overlayText: PropTypes.string,
};

export default DeveloperItem;
