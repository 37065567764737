import React from 'react';

import './projects.scss';

const Projects = () => (
  <div className='footer-projects'>
    <h2>Softserve Projects</h2>
    <div className="footer-projects-block">
      <ul>
        <li><a href="https://openeyes.org.ua/en" target='_blank'>Open Eyes Fund</a></li>
        <li><a href="https://opentech.softserveinc.com/en" target='_blank'>Open Tech</a></li>
      </ul>
    </div>
  </div>
);

export default Projects;
