import React from 'react';

import Menu from "./Menu";
import Contacts from './Contacts';
import Projects from './Projects';
import HotLinks from './HotLinks';

import './footer.scss'

const currentYear = () => (
  (new Date()).getFullYear()
);

const Footer = () => (
  <footer>
    <div className='footer-wrapper'>
      <div className='footer-top row'>
        <Contacts />
        <Projects />
        <HotLinks />
      </div>
      <div className='footer-bottom'>
        <Menu />
        <p className='copyright'>© Copyright {currentYear()} SoftServe</p>
      </div>
    </div>
  </footer>
);

export default Footer;
