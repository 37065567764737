import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import pluralize from 'pluralize';
import ReactHtmlParser from 'react-html-parser';

import ProjectSection from '../../Projects/ProjectsSection';
import Contributor from "./Contributor";

import './project-info.scss';

function ProjectInfo(props) {
  const { project } = props;
  const { long_description, technologies, languages, related_projects, developers } = project;

  const list = (list, listTitle) => {
    if (_.get(list, 'length', 0)) {
      return (
        <div className='info-item'>
          <h2>{listTitle}</h2>
          <ul>
            { _.map(list, (item, key)=> {
                return <li key={key}> {item} </li>;
              })
            }
          </ul>
        </div>
      );
    }
  };

  const contributors_list = _.map(developers, (developer) => {
    return (
      <Contributor key={developer.id} developer={developer} />
    );
  });

  const contributors = (developers) => {
    if (_.get(developers, 'length', 0)) {
      return (
        <section>
          <p className='title'>Authors and contributors</p>
          {contributors_list}
        </section>
      );
    }
  };

  const relatedProjectsList = (relatedProjects) => {
    if (_.get(relatedProjects, 'length', 0)) {
      return (
        <section>
          <p className='title'>Related projects</p>
          <ProjectSection projects = {relatedProjects} />
        </section>
      );
    }
  };

  return (
    <div className = 'wrapper-info'>
      <div className='flex-container container-info'>
        <div className='info main'>
          <div className='info-item'>
            <div className="about-project">
              {ReactHtmlParser(long_description)}
            </div>
          </div>
          {contributors(developers)}
        </div>
        <div className='info additional'>
          {list(technologies, pluralize('Technology', technologies ? technologies.length : 0))}
          {list(languages, pluralize('Language', languages ? languages.length : 0))}
        </div>
      </div>
      {relatedProjectsList(related_projects)}
    </div>
  );
}

ProjectInfo.defaultProps = {
  project: {}
};

ProjectInfo.propTypes = {
  project: PropTypes.shape({})
};

export default ProjectInfo;
