import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ProjectMetaTags from '../ProjectMetaTags';

import CommitteeItem from './CommitteeItem';
import './committee.scss';

import arrow from '../../images/icon-down-arrow.png';
import axios from 'axios';


function Committee() {
  const [committees, setCommittees] = useState([]);

  useEffect(() => {
    axios.get('/api/v1/committees.json')
      .then((res) => setCommittees(res.data))
      .catch(error => console.log(error));
  }, []);

  return (
    <main id="committee">
      <ProjectMetaTags title="Committee"/>
      <div className="greeting">
        <div className="greeting-wrapper">
          <span>Meet our<br/>committee</span>
          <img src={arrow} alt="icon-arrow-down"/>
        </div>
      </div>
      <div className="people">
        <div className="committee-wrapper">
          {committees.map((committee, key) => (
            <CommitteeItem
              key={committee.id}
              avatar={committee.avatar}
              fullName={committee.full_name}
              position={committee.position}
              linkedInUrl={committee.linkedin_url}
            />
          ))}
        </div>
      </div>
      <div className="get-in-touch">
        <div className="black-line"/>
        <span className="main">WANT TO GET<br/>IN TOUCH?</span>
        <span className="annotation">At SoftServe, we appreciate the individuals who make up our incredible company. We recognise the importance of each associate’s unique strengths, skills, and identity, no matter where they come from or what they may believe in. It’s what makes us exceptional.</span>
        <Link to="/submit-a-project" className="btn">Contact Us</Link>
      </div>
    </main>
  );
}

export default Committee;
